<template>
  <v-dialog max-width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="size === 'x-small'"
        text
        class="ma-0 pa-1"
        dense
        x-small
        v-bind="attrs"
        color="primary"
        v-on="on"
        @click="get_attributes()"
      >
        {{ value.friendly_name | str_limit(30) }}
      </v-btn>
      <v-btn
        v-else
        text
        class="ma-0 pa-1 mb-n2"
        dense
        small
        v-bind="attrs"
        color="primary"
        v-on="on"
        @click="get_attributes()"
      >
        {{ value.friendly_name | str_limit(30) }}
      </v-btn>
    </template>

    <template>
      <v-card style="text-align:center;" v-if="loading">
        <svg
          viewBox="-2 -5 90 46"
          width="100"
          height="50"
          style="margin-top:15px;"
        >
          <path
            class="path3"
            fill="#e4e4e4"
            stroke="rgba(5, 55, 104,.6)"
            stroke-width="2"
            d="M 33.6 8.8 L 22.35 8.8 L 22.35 34.4 L 11.3 34.4 L 11.3 8.8 L 0 8.8 L 0 0 L 33.6 0 L 33.6 8.8 Z"
          />
          <path
            class="path3"
            fill="#e4e4e4"
            stroke="rgba(113, 146, 176,.6)"
            stroke-width="2"
            d="M 79.15 34.4 L 68.2 34.4 L 68.2 22 Q 68.2 19.7 68.375 17.275 A 121.746 121.746 0 0 1 68.51 15.565 Q 68.567 14.898 68.627 14.307 A 57.598 57.598 0 0 1 68.75 13.2 Q 68.944 11.6 68.997 11.128 A 27.416 27.416 0 0 0 69 11.1 L 68.8 11.1 L 62.45 34.4 L 53.8 34.4 L 47.4 11.15 L 47.2 11.15 A 17.772 17.772 0 0 0 47.225 11.36 Q 47.292 11.905 47.475 13.225 A 47.654 47.654 0 0 1 47.586 14.087 Q 47.747 15.435 47.895 17.217 A 129.35 129.35 0 0 1 47.9 17.275 A 57.649 57.649 0 0 1 48.1 21.856 A 53.066 53.066 0 0 1 48.1 22 L 48.1 34.4 L 37.95 34.4 L 37.95 0 L 53.55 0 L 58.75 19.85 L 58.95 19.85 L 64.1 0 L 79.15 0 L 79.15 34.4 Z"
          />
        </svg>
      </v-card>
      <v-card class="pa-2 ma-0" v-else>
        <v-list>
          <v-list-item v-for="(a, i) in attributes" :key="i">
            <ChipValue
              :label="a.attribute.abbrev"
              copy="true"
              :value="a.value"
              :search="search_link(a)"
            ></ChipValue>
          </v-list-item>
        </v-list>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import UserService from "../store/UserService";
import ChipValue from "./ChipValue";

export default {
  name: "Fqdn",
  props: ["label", "value", "copy", "search", "size"],
  data: function() {
    return {
      remote: false,
      loading: true,
      attributes: []
    };
  },
  computed: {},
  methods: {
    search_link(a) {
      return (
        "/search/?search_type=1&issuer=" + a.value + "," + a.attribute.abbrev
      );
    },
    get_attributes() {
      this.loading = true;

      UserService.getLocalApi("fqdn-details/" + this.value.id).then(
        response => {
          this.attributes = response.data.attributes;
          this.loading = false;
        }
      );
    },
    copyText() {
      let textToCopy = this.$refs.textToCopy.$el.querySelector("input");
      textToCopy.select();
      document.execCommand("copy");
    }
  },
  components: { ChipValue }
};
</script>

<style scoped></style>
