<template>
  <v-container class="pl-0 pr-0 pt-0 ma-0" style="width:100%;">
    <v-text-field
      readonly
      dense
      style="height:24px;font-size:12px;width:100%;"
      ref="textToCopy"
      class="ma-0 pa-0 flex-grow-1"
      :value="display_value"
    >
      <template v-slot:prepend-inner class="ma-0 pa-0">
        <v-chip
          class="ma-0 mr-0 pa-3 mb-1"
          color="primary"
          label
          small
          outlined
          text
          style="font-weight:bold;"
        >
          {{ label }}
        </v-chip>
      </template>
      <template v-slot:append class="ma-0 pa-0">
        <v-btn
          v-if="search"
          class="ma-0 mr-0 pa-2"
          color="primary"
          label
          small
          text
          icon
          outlined
          :href="search"
          aria-label="Search"
        >
          <v-icon small>mdi-magnify</v-icon>
        </v-btn>
        <v-btn
          v-if="copy"
          class="ma-0 mr-0 pa-2 ml-1"
          color="primary"
          label
          small
          text
          outlined
          @click="copyText"
        >
          <v-icon small>mdi-content-copy</v-icon>
        </v-btn>
      </template>
    </v-text-field>
  </v-container>
</template>

<script>
export default {
  name: "ChipValue",
  props: ["label", "value", "copy", "search", "display"],
  data: function() {
    return {
      remote: false,
      loading: false
    };
  },
  computed: {
    display_value: function() {
      let values = [];
      let x;
      if (this.display) {
        for (x in this.value) {
          values.push(this.value[x][this.display]);
        }
        return values.join(", ");
      } else if (this.value !== null) {
        return this.value;
      } else {
        return "null";
      }
    }
  },
  methods: {
    copyText() {
      let textToCopy = this.$refs.textToCopy.$el.querySelector("input");
      textToCopy.select();
      document.execCommand("copy");
    }
  }
};
</script>

<style scoped></style>
